import "./style.css"
import { useNavigate,Link } from "react-router-dom";
import { Row, Col,Button,Card, Skeleton ,Image} from 'antd';
import { useEffect, useState } from "react";
import { useLanguage } from '../../Context/LanguageContext';
import LanguageToggle from "../../Components/LanguageToggle";
import Whatsapp from '../../Assets/ButtonWhatsapp.svg'
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Baloteli from "../../Assets/baloteli.png"
import RokBaloteli from "../../Assets/rokbaloteli.png"
import TunikBaloteli from "../../Assets/tunikbaloteli.png"
import KemejaBaloteli from "../../Assets/kemejabaloteli.png"
import Voal from "../../Assets/voal.png"
import Sifon from "../../Assets/sifon.png"
import Silk from "../../Assets/silk.png"
import JenisKainImage from "../../Assets/JenisKain1.png"

export const DetailKain5 = () => {
    const { language } = useLanguage();
    const navigate = useNavigate()
    
    const handleImageClick = () => {
        const phoneNumber = '+6287781819779';
        window.open(`https://wa.me/${phoneNumber}`, '_blank');
    };

    const [useSwiper, setUseSwiper] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setUseSwiper(window.innerWidth > 768); 
        };

        handleResize(); 
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    const category = [
        {
            "product_id": 1,
            "category_id": 1,
            "categories": {
                "id": 1,
                "name": "Rok Baloteli",
                "description": "Rok Baloteli Description",
                "image": RokBaloteli,
            }
        },
        {
            "product_id": 1,
            "category_id": 2,
            "categories": {
                "id": 2,
                "name": "Tunik Baloteli",
                "description": "Kemeja Baloteli Description",
                "image": TunikBaloteli,
            }
        },
        {
            "product_id": 1,
            "category_id": 3,
            "categories": {
                "id": 3,
                "name": "Kemeja Baloteli",
                "description": "Kemeja Baloteli Description",
                "image": KemejaBaloteli,
            }
        },
    ]

    const product = [
        {
            id: 1,
            image: Baloteli,
            name: "Baloteli",
        },
        {
            id: 2,
            image: Voal,
            name: "Voal",
        },
        {
            id: 3,
            image: Sifon,
            name: "Sifon",
        },
        {
            id: 4,
            image: Silk,
            name: "Silk",
        },
        {
            id: 6,
            image: JenisKainImage,
            name: "Maxmara",
        },
    ]

    return(
        <>
            <LanguageToggle />

            <div className="ContainerBG">
                <div className="BGDetail">
                    <h1>Detail Jenis Kain</h1>
                </div>
            </div>
            
            <div className="containerLayanan">
                <Row gutter={[16, 16]}>
                    <Col xs={{ span: 24, order: 2 }} sm={{ span: 24, order: 2 }} md={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }}>
                        <div className="grid-item" style={{ marginRight: '5%' }}>
                            <div className="mainImageDetail">
                                <img src={JenisKainImage} alt="Gambar Scuba" />
                            </div>
                        </div>
                    </Col>
                    <Col xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ span: 12, order: 2 }} lg={{ span: 12, order: 2}}>
                        <div className="grid-item">
                            <h1>Kain Scuba</h1>
                            <p>
                                Kain balotelli adalah salah satu jenis kain yang cukup sering digunakan sebagai
                                bahan dasar pembuatan produk pakaian. Meski, tidak sedikit orang yang kurang
                                mengenali jenis kain ini. Apakah Anda termasuk orang yang belum mengenal
                                kain balotelli?
                            </p>
                            <p>
                                Kain balotelli bisa dikatakan tergolong dalam kain katun, meski dalam pembuatannya 
                                tidak murni menggunakan katun. Banyak pengusaha fashion yang kepincut dengan kain 
                                balotelli karena memiliki serat-serat unik berbentuk kotak-kotak kecil.
                            </p>
                            <p>
                                Serat kain balotelli juga terkenal sangat rapat, sehingga tidak membutuhkan 
                                vuring untuk pembuatan produk pakaian karena tidak akan menerawang. Meski tebal, 
                                kain balotelli mampu memberikan kesejukan saat dikenakan lho.
                            </p>
                            <Button style={{ display: 'flex', alignItems: 'center' }} onClick={handleImageClick}>
                                <img src={Whatsapp} alt="WhatsApp Logo" style={{width:'20px',height:'20px', marginRight: '8px' }} />
                                Pesan Sekarang
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>

            <div className="containerLayanan">
                <Row gutter={[16, 16]}>
                    <Col xs={{ span: 24, order: 2 }} sm={{ span: 24, order: 2 }} md={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }}>
                        <div className="grid-item" style={{ marginRight: '5%' }}>
                            <h1>Jasa Cetak Kain Scuba</h1>
                            <p>
                                Kain balotelli terkenal memiliki banyak pilihan warna. Mulai dari warna 
                                cerah hingga warna polos. Selain itu, jenis kain ini juga memiliki beragam motif.
                            </p>
                            <p>
                                Sebagai pengusaha fashion dan/atau desainer pakaian, memanfaatkan kain balotelli 
                                untuk pembuatan produk pakaian yang berkualitas adalah pilihan yang tepat. Anda pun 
                                bisa menyematkan motif secara custom sesuai kebutuhan pada jenis kain ini.
                            </p>
                            <p>
                                Sebagai penyedia jasa print kain balotelli profesional dan berpengalaman, 
                                Mahesa dapat membantu Anda mencetakkan motif printing berkualitas apik secara 
                                custom dan/atau sesuai kebutuhan. Beberapa produk dari kain balotelli yang bisa 
                                dicetak motif printing di antaranya: busana muslim balotelli, tunik balotelli dan 
                                kemeja kantor balotelli.
                            </p>
                            <p>
                                Anda bisa menggunakan jasa cetak kain balotelli profesional dan berpengalaman dari 
                                Mahesa untuk mengaplikasikan motif printing yang berkualitas dan bagus. Sudah banyak 
                                orang yang memercayakan Mahesa guna mengaplikasikan motif printing ke berbagai jenis kain. 
                                Sekarang giliran Anda yang menjadi klien kami!
                            </p>
                        </div>
                    </Col>
                    <Col xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ span: 12, order: 2 }} lg={{ span: 12, order: 2}}>
                        <div className="grid-item">
                            <h1>Kelebihan Kain Scuba</h1>
                            <p>
                                Tidak hanya memiliki banyak pilihan warna dan motif, kain balotelli 
                                juga memiliki keunggulan lain. Berikut di antaranya:
                            </p>
                            <p>
                                1. Adem Saat Dikenakan 
                                <br></br>
                                <span>
                                    Berbagai produk pakaian berbahan kain balotelli memiliki sifat adem. 
                                    Sehingga, orang yang mengenakannya akan merasakan kenyamanan dan tidak kegerahan. 
                                    Apalagi jenis kain balotelli yang dikenakannya dengan grade yang bagus.
                                </span>
                            </p>
                            <p>
                                2. Bahan Tebal Tapi Ringan Dikenakan
                                <br></br>
                                <span>
                                    Kain balotelli, seperti yang kami jelaskan sebelumnya, memiliki karakter
                                     yang tebal. Meski begitu, jenis kain ini cukup ringan saat dikenakan. 
                                     Inilah alasan mengapa kain balotelli cocok untuk dijadikan bahan 
                                     pembuatan busana muslim.
                                </span>
                            </p>
                            <p>
                                3. Mudah Diatur
                                <br></br>
                                <span>
                                    Produk berbahan kain balotelli tidak mudah kusut meski dikenakan
                                    seharian. Selain itu, jenis kain ini juga mudah diatur, termasuk 
                                    saat dibuat menjadi hijab printing.
                                </span>
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>

            <div className="site-card-wrapper" style={{ margin: '0 5%' }}>
                <h1>Contoh Produk Yang Dihasilkan Kain Scuba</h1>
                <Row gutter={[16, 16]}>
                    {category.map((value, index) => (
                    <Col key={index} xs={24} sm={12} md={8} lg={8}>
                        <Link to={`/detail-kain/${value.categories.id}`}>
                            <div 
                                style={{
                                    overflow: 'hidden',
                                    margin: '10px 10px 20px 10px'
                                }}
                            >
                                <img src={value.categories.image} alt={value.categories.name} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                <h1>{value.categories.name}</h1>
                            </div>
                        </Link>
                    </Col>
                    ))}
                </Row>
            </div>

            {useSwiper ? (
                <div className="ContainerProducts">
                    <h1>Bahan Kain Lainnya</h1>
                    <Swiper
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        slidesPerView={3}
                        spaceBetween={50}
                        freeMode={true} navigation={true}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                        className="mySwiper"
                    >
                        {product.map((item, index) => (
                            <SwiperSlide key={index} virtualIndex={index}>
                                <div 
                                    style={{
                                        overflow: 'hidden',
                                        margin: '10px 10px 20px 10px'
                                    }}
                                >
                                    <Image src={item.image} alt={item.name} style={{ width: '400px', height: '400px', objectFit: 'cover' }} />
                                    <h1>{item.name}</h1>
                                    <br/>
                                    <Button onClick={() => {
                                        navigate(`/detail-kain/${item.id}`)
                                        navigate(0)
                                    }}>Lihat Detail Kain</Button>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            ) : (
                <div className="ContainerProducts">
                    <h1>Bahan Kain Lainnya</h1>
                    <Row justify="space-around" align="middle">
                        {product.map((item, index) => (
                            <Col key={index} xs={24} sm={12} md={8} lg={8}>
                                <div 
                                    style={{
                                        overflow: 'hidden',
                                        margin: '10px 10px 20px 10px'
                                    }}
                                >
                                    <Image src={item.image} alt={item.name} style={{ width: '400px', height: '400px', objectFit: 'cover' }} />
                                    <h1>{item.name}</h1>
                                    <br/>
                                    <Button onClick={() => {
                                        navigate(`/detail-kain/${item.id}`)
                                        navigate(0)
                                    }}>Lihat Detail Kain</Button>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>
            )}
        </>
    )
}