import React from 'react';
import Whatsapp from "../Assets/whatsapp.svg"


const ChatWhatsapp = () => {
  const handleImageClick = () => {
    const phoneNumber = '+6287781819779';
    window.open(`https://wa.me/${phoneNumber}`, '_blank');
  };

  return (
    <img
      src={Whatsapp}
      alt="Chat Icon"
      className="chat-icon"
      onClick={handleImageClick}
    />
  );
};

export default ChatWhatsapp;
