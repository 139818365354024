import "./style.css"
import { Row, Col,Button } from 'antd';
import ListFoto1 from "../../Assets/List-foto-1.png"
import ListFoto2 from "../../Assets/List-foto-2.png"
import ListFoto3 from "../../Assets/List-foto-3.png"
import ListFoto4 from "../../Assets/List-foto-4.svg"
import ListFoto5 from "../../Assets/List-foto-5.png"
import ListFoto6 from "../../Assets/List-foto-6.png"
import ListFoto7 from "../../Assets/List-foto-7.svg"
import ListFoto8 from "../../Assets/List-foto-8.svg"
import ListFoto9 from "../../Assets/List-foto-9.svg"
import Print from "../../Assets/Print.png"
import Press from "../../Assets/Press.png"
import LaserCut from "../../Assets/LaserCut.png"
import BingungDesain from "../../Assets/BingungDesain.svg"
import ChatWhatsapp from '../../Components/ChatWhatsapp';
import { useLanguage } from '../../Context/LanguageContext';
import LanguageToggle from "../../Components/LanguageToggle";

export const Layanan = () => {
    const { language } = useLanguage();
    const handleImageClick = () => {
        const phoneNumber = '+6287781819779';
        window.open(`https://wa.me/${phoneNumber}`, '_blank');
      };

    return (
        <>
            <LanguageToggle />

            <div className="ContainerBG">
                <div className="BGLayanan">
                    <h1>{language === 'id' ? 'Layanan Mahesa Sublim' : 'Mahesa Sublim Services'}</h1>
                </div>
            </div>

            <div className="containerLayanan">
                <Row gutter={[16, 16]}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                        <div className="grid-item">
                            <div className="mainImage">
                                <img src={Print} alt="Gambar Print" />
                            </div>
                        </div>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                        <div className="grid-item" style={{ marginLeft: '5%' }}>
                            <h1>{language === 'id' ? 'Print' : 'Printing'}</h1>
                            <p>
                            {language === 'id'
                                ? 'Mewujudkan desain Anda dengan kejelasan dan kecerahan yang luar biasa pada kain! Layanan Printing Kain kami di Mahesa Sublim menggunakan teknologi terkini untuk menghasilkan cetakan yang tajam dan warna yang memukau.'
                                : 'Bring your designs to life with exceptional clarity and brightness on fabric! Our Fabric Printing service at Mahesa Sublim uses the latest technology to produce sharp prints and stunning colors.'}
                            </p>
                            <div className="image-container">
                                <img src={ListFoto1} alt="Gambar 1" />
                                <img src={ListFoto2} alt="Gambar 2" />
                                <img src={ListFoto3} alt="Gambar 3" />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>




            <div className="containerLayanan">
                <Row gutter={[16, 16]}>
                    <Col xs={{ span: 24, order: 2 }} sm={{ span: 24, order: 2 }} md={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }}>
                        <div className="grid-item" style={{ marginRight: '5%' }}>
                            <h1>{language === 'id' ? 'Press' : 'Printing Press'}</h1>
                            <p>
                            {language === 'id'
                                ? 'Kami di Mahesa Sublim hadir untuk memberikan sentuhan kreatif dan keindahan pada kain Anda. Dengan Printing Press Kain berkualitas tinggi kami, wujudkan desain Anda pada kain dengan kejelasan warna yang memukau dan ketajaman detail yang luar biasa.'
                                : 'We at Mahesa Sublim are here to provide creative touches and beauty to your fabric. With our high-quality Fabric Printing Press, bring your designs to life on fabric with stunning color clarity and exceptional detail sharpness.'}
                            </p>
                            <div className="image-container">
                                <img src={ListFoto4} alt="Gambar 4" />
                                <img src={ListFoto5} alt="Gambar 5" />
                                <img src={ListFoto6} alt="Gambar 6" />
                            </div>
                        </div>
                    </Col>
                    <Col xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ span: 12, order: 2 }} lg={{ span: 12, order: 2}}>
                        <div className="grid-item">
                            <div className="mainImage">
                                <img src={Press} alt="Gambar Press" />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>


            <div className="containerLayanan">
                <Row gutter={[16, 16]}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                        <div className="grid-item">
                            <div className="mainImage">
                                <img src={LaserCut} alt="Gambar LaserCut" />
                            </div>
                        </div>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                        <div className="grid-item" style={{ marginLeft: '5%' }}>
                            <h1>{language === 'id' ? 'Laser Cut' : 'Laser Cut Fabric'}</h1>
                            <p>
                            {language === 'id'
                                ? 'Tambahkan sentuhan unik pada kain Anda dengan Layanan Laser Cut Kain Mahesa Sublim. Dengan teknologi mutakhir, kami memberikan potongan kain dengan presisi tinggi, menciptakan motif dekoratif dan desain yang membuat kain Anda menjadi karya seni yang hidup.'
                                : 'Add a unique touch to your fabric with Mahesa Sublim\'s Laser Cut Fabric Service. With state-of-the-art technology, we provide high-precision fabric cuts, creating decorative patterns and designs that turn your fabric into a living work of art.'}
                            </p>
                            <div className="image-container">
                                <img src={ListFoto7} alt="Gambar 7" />
                                <img src={ListFoto8} alt="Gambar 8" />
                                <img src={ListFoto9} alt="Gambar 9" />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>


            <div className="containerBingung">
                <div className="containerBingungDesain">
                    <Row gutter={[16, 16]}>
                        <Col xs={{ span: 24, order: 2 }} sm={{ span: 24, order: 2 }} md={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }} className="gridDesain2">
                            <div className="grid-item gridDesain2" style={{ marginRight: '5%' }}>
                                <h1>{language === 'id' ? 'Bingung milih desain?' : 'Confused about choosing a design?'}</h1>
                                <p>
                                {language === 'id'
                                    ? 'Tidak mempunyai desain sendiri? Tidak masalah, anda bisa menggunakan rekomendasi design yang Mahesa telah disediakan. Klik disini untuk info lebih lanjut'
                                    : "Don't have your own design? No problem, you can use the design recommendations provided by Mahesa. Click here for more information"}
                                </p>
                                <Button className="buttonStyle" onClick={handleImageClick}>{language === 'id' ? 'Kontak' : 'Contact'}</Button>
                            </div>
                        </Col>
                        <Col xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ span: 12, order: 2 }} lg={{ span: 12, order: 2}} className="gridDesain2">
                            <div className="grid-item">
                                <div className="mainImage">
                                    <img src={BingungDesain} alt="Gambar Press" />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>


            <ChatWhatsapp/>
        </>
    )
}