import { Row, Col, Statistic, Skeleton } from "antd"
import { DollarCircleFilled , SyncOutlined, CheckCircleFilled} from '@ant-design/icons';
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../Context/UserContext";
import axios from "axios";

const colStyle = {
    backgroundColor: '#fff',
    padding: '10px',
    borderLeft: '5px solid #1677FF'
}

export const Dashboard = () => {
    return (
        <>
        <h1>ini dashboard</h1>
        </>
    )
}