import { Layout, Menu } from "antd";
import {
  SettingOutlined,
  UserOutlined,
  KeyOutlined,
  DashboardOutlined,
  ProfileOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import { useContext } from "react";
import { UserContext } from "../../Context/UserContext";
import { useHref, useNavigate,useParams } from "react-router-dom";

const { Sider } = Layout;

export const Sidebar = () => {
  let {id} = useParams()
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const url = useHref();

  const role = user?.role === "USER" ? "user" : "admin";

  let items = [
    {
      label: "Dashboard",
      key: "/dashboard",
      icon: <DashboardOutlined />,
    },
    {
      label: "Category",
      key: "/Category",
      icon: <ProfileOutlined />,
    },
    {
      label: "Product",
      key: "/Product",
      icon: <ProfileOutlined />,
    },
    {
      label: "Artikel",
      key: "/Artikel",
      icon: <ProfileOutlined />,
    },
    {
      label: "Setting",
      key: "/setting",
      icon: <SettingOutlined />,
      children: [
        {
          label: "Profile",
          key: `setting/profile/${role}`,
          icon: <UserOutlined />,
        },
        {
          label: "Change Password",
          key: `setting/change-password/${role}`,
          icon: <KeyOutlined />,
        },
      ],
    },
  ];

  if (user?.role === "USER") {
    items = items.filter(
      (item) =>
        item.key !== "/user" &&
        item.key !== "/admin-products" &&
        item.key !== "/category" 
    );
  }

  const noSidebar = [
    "/login/user",
    "login/admin",
    "/kontak",
    "/artikel",
    "/not-found",
    "/layanan-kami",
    "/jenis-kain",
    "/tentang-kami",
    `/detail-kain/${id}`,
    `/detail-kain/1`,
    `/detail-kain/2`,
    `/detail-kain/3`,
    `/detail-kain/4`,
    `/detail-kain/5`,
    `/detail-kain/6`,
    `/detail-artikel/1`,
    `/detail-artikel/2`,
    `/detail-artikel/3`,
    `/detail-artikel/4`,
    "/",
  ];

  if (url.includes("/product")) {
    noSidebar.push(url);
  }

  const sidebar = noSidebar.includes(url);

  const handleMenu = (props) => {
    navigate(props.key);
  };

  return (
    user &&
    !sidebar && (
      <Sider width={220} theme="light">
        <Menu
          mode="inline"
          defaultSelectedKeys={[url]}
          style={{
            paddingTop: "10px",
            height: "100%",
            borderRight: 0,
          }}
          items={items}
          onClick={handleMenu}
        />
      </Sider>
    )
  );
};
