import "./style.css"
// import { Image } from 'antd';
import pict1 from "../../Assets/Pict1.png"
// import alur1 from "../../Assets/alur1.png"
// import alur2 from "../../Assets/alur2.png"
// import alur3 from "../../Assets/alur3.png"
import Maps from "../../Assets/Maps.svg"
import alurKerja from "../../Assets/alurKerja.png"
import sertifikat from "../../Assets/sertifikat.png"
import { Row, Col} from 'antd';
import ChatWhatsapp from '../../Components/ChatWhatsapp';
import { useLanguage } from '../../Context/LanguageContext';
import LanguageToggle from "../../Components/LanguageToggle";

export const TentangKami = () => {
    const { language } = useLanguage();

    return(
        <>
            <LanguageToggle />

            <div className="ContainerBG">
                <div className="BG">
                    <h1>{language === 'id' ? 'Tentang Kami' : 'About Us'}</h1>
                </div>
            </div>
            <div className="ContentKami">
                <h1>Mahesa Sublim</h1>
                <p>
                    {language === 'id' ? (
                        'Di Mahesa Sublim, kami tidak hanya sekadar menyediakan layanan cetak kain, tetapi juga membawa pengalaman yang berbeda dalam dunia cetak dengan berbagai metode. Kami dapat mewujudkan dan menghadirkan warna-warna yang hidup, detail yang presisi, dan hasil cetak yang tahan lama.'
                    ) : (
                        'At Mahesa Sublim, we not only provide fabric printing services but also bring a different experience to the world of printing with various methods. We can realize and present vivid colors, precise details, and durable prints.'
                    )}
                </p>
            </div>

            <div className="ContainerVisiMisi">
                <Row gutter={[16, 16]}>
                    <Col xs={{ span: 24, order: 2 }} sm={{ span: 24, order: 2 }} md={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }}>
                        <div className="grid-item" style={{ marginRight: '5%' }}>
                            <h2>{language === 'id' ? 'Visi & Misi' : 'Vision & Mission'}</h2>
                            <h1>{language === 'id' ? 'Misi Mahesa Sublim' : 'Mahesa Sublim\'s Mission'}</h1>
                            <p>
                                {language === 'id' ? (
                                'Mahesa siap memberikan solusi menyeluruh untuk para mitra usaha lainya melalui layanan desain, print kain, heat press, dan fabric laser cutting.'
                                ) : (
                                'Mahesa is ready to provide comprehensive solutions for other business partners through design services, fabric printing, heat press, and fabric laser cutting.'
                                )}
                            </p>
                            <p className="point">
                                <br />
                                {language === 'id' ? '1. Membantu UKM Kreatif Indonesia agar dapat bersaing di pasar Indonesia dan Global' : '1. Assist Creative SMEs in Indonesia to compete in the Indonesian and Global markets'}
                                <br />
                                {language === 'id' ? '2. Menjadi mitra usaha strategis para pelaku ekonomi kreatif melalui edukasi & solusi untuk menghasilkan produk berkualitas' : '2. Become a strategic business partner for creative economic players through education and solutions to produce quality products'}
                                <br />
                                {language === 'id' ? '3. Menjadi mitra usaha strategis para pelaku ekonomi kreatif melalui edukasi & solusi untuk menghasilkan produk berkualitas' : '3. Become a strategic business partner for creative economic players through education and solutions to produce quality products'}
                                <br />
                                {language === 'id' ? '4. Membantu pemerintah dalam menciptakan lapangan kerja bagi masyarakat Indonesia' : '4. Assist the government in creating job opportunities for the people of Indonesia'}
                            </p>
                        </div>
                    </Col>
                    <Col xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ span: 12, order: 2 }} lg={{ span: 12, order: 2}}>
                        <div className="grid-item">
                            <div className="mainImage">
                                <img src={pict1} alt="Gambar pic1" />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

            <div className="contenerMargin">
                <div className="ContainerAlurKerjaTentangKami">
                <Row gutter={[16, 16]}>
                        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                            <div className="grid-item-alurKerja">
                                <div className="AlurImage">
                                    <img src={alurKerja} alt="Gambar pic1" />
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ span: 12, order: 2 }} lg={{ span: 12, order: 2}}>
                            <div className="grid-item-alurKerja">
                                <h2>{language === 'id' ? 'Alur Kerja' : 'Workflow'}</h2>
                                <h1>{language === 'id' ? 'Rangkaian Alur Kerja di Mahesa' : 'Workflow at Mahesa'}</h1>
                                <h2>{language === 'id' ? 'Alur kerja melewati proses yang sangat ketat, sempurna, dan berkualitas sebelum kami lepas ke konsumen.' : 'The workflow goes through a very strict, perfect, and quality process before we release it to consumers.'}</h2>
                                <h3>1. {language === 'id' ? 'Konsultasi Desain' : 'Design Consultation'}</h3>
                                <h2>{language === 'id' ? 'Sebelum memulai proses cetak, tim Mahesa Sublim menyediakan layanan konsultasi desain.' : 'Before starting the printing process, the Mahesa Sublim team provides design consultation services.'}</h2>
                                <h3>2. {language === 'id' ? 'Pemilihan Bahan Berkualitas' : 'Selection of Quality Materials'}</h3>
                                <h2>{language === 'id' ? 'Kami mengutamakan kualitas bahan sebagai langkah awal menuju hasil cetak terbaik.' : 'We prioritize the quality of materials as the first step towards the best printing results.'}</h2>
                                <h3>3. {language === 'id' ? 'Quality Control' : 'Quality Control'}</h3>
                                <h2>{language === 'id' ? 'Setiap produk melewati proses kontrol kualitas ketat sebelum dikirimkan ke pelanggan.' : 'Each product goes through a strict quality control process before being delivered to customers.'}</h2>
                                <h3>4. {language === 'id' ? 'Production Process' : 'Production Process'}</h3>
                                <h2>{language === 'id' ? 'Proses produksi yang terarah dan inovatif kami memastikan efisiensi tinggi dalam mencetak kain, tanpa mengorbankan keakuratan dan kualitas cetakan.' : 'Our directed and innovative production process ensures high efficiency in fabric printing, without compromising accuracy and print quality.'}</h2>
                                <h3>5. {language === 'id' ? 'Delivery Time' : 'Delivery Time'}</h3>
                                <h2>{language === 'id' ? 'Kami berkomitmen untuk memberikan pengiriman tepat waktu, menjadwalkan setiap pengiriman sesuai dengan kebutuhan Anda agar Anda dapat mengandalkan pasokan kain dengan konsistensi.' : 'We are committed to providing timely delivery, scheduling each delivery according to your needs so that you can rely on a consistent supply of fabric.'}</h2>
                                <h3>6. {language === 'id' ? 'Aftersales' : 'Aftersales'}</h3>
                                <h2>{language === 'id' ? 'Pelayanan penjualan kami yang responsif dan berorientasi pada kepuasan pelanggan membantu menjembatani kebutuhan Anda.' : 'Our responsive and customer satisfaction-oriented sales service helps bridge your needs.'}</h2>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            

            <div className="Containermaps">
                    <div className="AlurImage">
                        <img src={Maps} alt="Gambar Maps" />
                    </div>
                <h1>{language === 'id' ? 'Temukan Mahesa Di Lokasi Anda' : 'Find Mahesa In Your Location'}</h1>

                <div class="ContainerCardLokasi">
                    <div class="cardLokasi">
                        <h2>Jakarta</h2>
                        <p>
                        Jl. Garuda No. 8B RT 001/001 Kel.Kemayoran Kec. Kemayoran Jakarta Pusat - DKI Jakarta 10620
                        </p>
                    </div>
                    <div class="cardLokasi">
                        <h2>Bandung</h2>
                        <p>
                        Komplek Indah Raya, Jl. Batununggal Indah Raya No.108, Batununggal, Bandung Kidul, Kota Bandung, Jawa Barat 40267
                        </p>
                    </div>
                    <div class="cardLokasi">
                        <h2>Solo</h2>
                        <p>
                        Jl. Melon Raya No.30, Karangasem, Kec. Laweyan, Kota Surakarta, Jawa Tengah 57145
                        </p>
                    </div>
                </div>
            </div>

            <div className="ContainerSertifikat">
                <h1>{language === 'id' ? 'Sertifikat' : 'Certificate'}</h1>
                <p>
                {language === 'id'
                    ? 'Kami telah dipercaya oleh berbagai banyak konsumen untuk menekuni bidang printing, itu dibuktikan dengan berbagai sertifikat yang telah kami miliki seperti dibawah ini.'
                    : 'We have been trusted by various consumers to engage in the printing field, as evidenced by various certificates that we have obtained as shown below.'}
                </p>

                <div className="ContainerCardSertifikat">
                    <Row gutter={16}>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <div style={{ marginBottom: '20px', marginTop:'25%'}}>
                                <div className="mainImageKami">
                                    <img src={sertifikat} alt="Gambar pic1" />
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <div style={{ marginBottom: '20px', marginTop:'10%'}}>
                                <div className="mainImageKami">
                                    <img src={sertifikat} alt="Gambar pic1" style={{ width: 'calc(100% - 10px)' }}/>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <div style={{ marginBottom: '20px', marginTop:'25%'}}>
                                <div className="mainImageKami">
                                    <img src={sertifikat} alt="Gambar pic1"/>
                                </div>
                            </div>
                        </Col>
                        </Row>
                </div>
            </div>


            <ChatWhatsapp/>
        </>
    )
}