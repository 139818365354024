import { Button, Result } from 'antd';
import { Link } from 'react-router-dom';

export const NotFound = () => {
    return (
    <Result
        style={{minHeight: '100vh'}}
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
        extra={<Button type="primary"><Link to='/'>Back Home</Link></Button>}
    />
    )

}