import './App.css';
import 'antd/dist/reset.css';
import { CustomRoute } from './Routes/Routes';
import { UserProvider } from './Context/UserContext';
import { LanguageProvider } from './Context/LanguageContext';

function App() {
  return (
    <div>
        <LanguageProvider>
            <UserProvider>
                <CustomRoute/>
            </UserProvider>
        </LanguageProvider>
    </div>
  );
}

export default App;
