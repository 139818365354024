import { Upload , Row, Col, message, Button, Form, Input, Typography, Select, Skeleton,DatePicker  } from 'antd';
import axios from 'axios';
import { UserContext } from "../../Context/UserContext";
import { useState, useContext, useEffect } from 'react';
import dayjs from 'dayjs';


export const Profile = () => {
    const [form] = Form.useForm();
    const {user} = useContext(UserContext)
    const [loaderButton, setLoaderButton]= useState(false)
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)

    const role = user?.role === 'USER' ? 'user' : 'admin'

    useEffect(()=>{
        if(!loaderButton){
            console.log("masuk ke axios get",role)
            axios.get(`${process.env.REACT_APP_API_URL}/profile`, { headers: { Authorization: "Bearer " + user.token } })
            .then(res => {
                setCurrentUser(res.data)
                setLoading(false)
            }).catch(err => {
                console.log(err);
                setLoading(false)
            })
        }
    }, [loaderButton])

    const onFinish = (values) => {
        setLoaderButton(true)
        values = {...values }
        axios.patch(`${process.env.REACT_APP_API_URL}/profile`, values, { headers: { Authorization: "Bearer " + user.token } })
            .then(res => {
                message.success('Profile changed successfully!', [5])
                setLoaderButton(false)
            }).catch(err => {
                console.log(err);
                setLoaderButton(false)
            })   
    }

    return (
        <div className="dashboard-container">
            <Row>
                <Typography.Title level={3}>Your Profile</Typography.Title>
                <Col span={24}>
                </Col>
                {loading ? <Skeleton active style={{marginTop: '20px'}} /> :
                <Col span={24} style={{marginTop: '20px'}}>
                <Form
                    initialValues={{
                        fullname: currentUser?.fullname,
                        NIS: currentUser?.NIS,
                        Jurusan: currentUser?.Jurusan,
                        Tahun_masuk: currentUser?.Tahun_masuk,
                        Jenis_kelamin: currentUser?.Jenis_kelamin,
                        Tempat_lahir: currentUser?.Tempat_lahir,
                        Tanggal_lahir: currentUser ? dayjs(currentUser.Tanggal_lahir) : undefined
                    }}
                    form={form}
                    name='change-password'
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Row gutter={[24, 0]}>
                        <Col xs={24} sm={24} md={12}>
                            <Form.Item
                            name="fullname"
                            label="Fullname"
                            >
                            <Input />
                            </Form.Item> 
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <Form.Item
                            name="NIS"
                            label="NIS"
                            >
                            <Input disabled />
                            </Form.Item> 
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <Form.Item
                            name="Jenis_kelamin"
                            label="Jenis kelamin"
                            >
                            <Select
                                allowClear
                                options={[
                                    {
                                        value: 'male',
                                        label: 'Male'
                                    },
                                    {
                                        value: 'female',
                                        label: 'Female'
                                    },
                                    {
                                        value: 'other',
                                        label: 'Other'
                                    }
                                ]}
                            />
                            </Form.Item> 
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <Form.Item
                            name="Jurusan"
                            label="Jurusan"
                            >
                            <Input />
                            </Form.Item> 
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <Form.Item
                            name="Tahun_masuk"
                            label="Tahun masuk"
                            >
                            <Input />
                            </Form.Item> 
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <Form.Item
                            name="Tanggal_lahir"
                            label="Tanggal lahir"
                            >
                                <DatePicker style={{ width: '100%' }} format={"DD-MM-YYYY"}/>
                            </Form.Item> 
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <Form.Item
                            name="Tempat_lahir"
                            label="Tempat lahir"
                            >
                            <Input />
                            </Form.Item> 
                        </Col>
                        
                    </Row>
                    <Form.Item>
                        <Button loading={loaderButton} type="primary" htmlType="submit">Save</Button>
                    </Form.Item>                                    
                </Form>                          
                </Col>}
            </Row>
        </div>
    )
}