import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ProtectedAuth } from "../Helper/ProtectedAuth";
import { ProtectedRoute } from "../Helper/ProtectedRoute";
import { Main } from "../Layout/Main";
import { Login } from "../Pages/Auth/Login";
import { Register } from "../Pages/Auth/Register";
import { TentangKami } from "../Pages/TentangKami";
import { NotFound } from "../Pages/NotFound";
import { SettingLayout } from "../Pages/Settings";
import { ChangePassword } from "../Pages/Settings/ChangePassword";
import { Profile } from "../Pages/Settings/Profile";
import { Dashboard } from "../Pages/Dashboard";
import { HomePage } from "../Pages/Homepage";
import { JenisKain } from "../Pages/JenisKain/product";
import { Layanan } from "../Pages/Layanan";
import { Artikel } from "../Pages/Artikel";
import { Kontak } from "../Pages/Kontak";
import { TableCategory } from "../Pages/Admin/Category";
import { DetailKain1 } from "../Pages/DetailKain/detailproduct1";
import { DetailKain2 } from "../Pages/DetailKain/detailproduct2";
import { DetailKain3 } from "../Pages/DetailKain/detailproduct3";
import { DetailKain4 } from "../Pages/DetailKain/detailproduct4";
import { DetailKain5 } from "../Pages/DetailKain/detailproduct5";
import { DetailKain6 } from "../Pages/DetailKain/detailproduct6";
import { DetailArtikel1 } from "../Pages/DetailArtikel/detailartikel1";
import { DetailArtikel2 } from "../Pages/DetailArtikel/detailartikel2";
import { DetailArtikel3 } from "../Pages/DetailArtikel/detailartikel3";
import { DetailArtikel4 } from "../Pages/DetailArtikel/detailartikel4";

export const CustomRoute = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />}>
          <Route index element={<HomePage />} />
          <Route
            path="login/:role"
            element={
              <ProtectedAuth>
                <Login />
              </ProtectedAuth>
            }
          />
          <Route
            path="register"
            element={
              <ProtectedAuth>
                <Register />
              </ProtectedAuth>
            }
          />
          <Route
            path="dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route path="category" element={<ProtectedRoute> <TableCategory /></ProtectedRoute>}/>
          <Route path="setting" element={<SettingLayout />}>
            <Route path="profile/:role" element={<Profile />} />
            <Route path="change-password/:role" element={<ChangePassword />} />
          </Route>
          <Route path="tentang-kami" element={<TentangKami />} />
          <Route path="jenis-kain" element={<JenisKain />} />
          {/* <Route path="detail-kain/:id" element={<DetailKain />} /> */}
          <Route path="detail-kain/1" element={<DetailKain1 />} />
          <Route path="detail-kain/2" element={<DetailKain2 />} />
          <Route path="detail-kain/3" element={<DetailKain3 />} />
          <Route path="detail-kain/4" element={<DetailKain4 />} />
          <Route path="detail-kain/5" element={<DetailKain5 />} />
          <Route path="detail-kain/6" element={<DetailKain6 />} />
          <Route path="layanan-kami" element={<Layanan />} />
          <Route path="artikel" element={<Artikel />} />
          <Route path="detail-artikel/1" element={<DetailArtikel1 />} />
          <Route path="detail-artikel/2" element={<DetailArtikel2 />} />
          <Route path="detail-artikel/3" element={<DetailArtikel3 />} />
          <Route path="detail-artikel/4" element={<DetailArtikel4 />} />

          <Route path="kontak" element={<Kontak />} />
          <Route path="not-found" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/not-found" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
