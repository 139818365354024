import { Row, Col, Typography, Form, Input, Button, message } from "antd";
import { useContext, useState } from "react";
import { UserContext } from "../../Context/UserContext";
import axios from "axios";

export const ChangePassword = () => {
  const [form] = Form.useForm();
  const { user } = useContext(UserContext);
  const [loaderButton, setLoaderButton] = useState(false);

  const role = user?.role === "USER" ? "user" : "admin";

  const onFinish = (values) => {
    setLoaderButton(true);
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/change-password`,
        values,
        { headers: { Authorization: "Bearer " + user.token } }
      )
      .then((res) => {
        message.success("Password changed successfully!", [5]);
        form.resetFields();
        setLoaderButton(false);
      })
      .catch((err) => {
        console.log(err);
        message.error(err.response.data.msg, [5]);
        setLoaderButton(false);
      });
  };

  return (
    <div className="dashboard-container">
      <Row>
        <Col sm={24} xs={24} md={12}>
          <Typography.Title level={3}>Change Password</Typography.Title>
          <Form
            form={form}
            name="change-password"
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item
              name="newPassword"
              label="New Password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
                {
                  min: 6,
                  message: "Min length 6 character!",
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              label="Confirm Password"
              dependencies={["newPassword"]}
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
                {
                  min: 6,
                  message: "Min length 6 character!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="oldPassword"
              label="Current Password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Button loading={loaderButton} type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};
