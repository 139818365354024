import { useContext } from "react"
import { Navigate, Outlet } from "react-router-dom"
import { UserContext } from "../../Context/UserContext"

export const SettingLayout = () => {
    const {user} = useContext(UserContext)

    if(!user){
        return <Navigate to='/not-found' />
    }

    return(
        <Outlet/>
    )
}