import { Space, Table, Typography, Skeleton, Button, Modal, Input, message, Popconfirm,Form,Upload } from 'antd';
import { EditOutlined, DeleteOutlined, DownSquareOutlined, UpSquareOutlined,UploadOutlined  } from '@ant-design/icons';
import {useState, useContext, useEffect} from 'react'
import { UserContext } from '../../../Context/UserContext';
import axios from 'axios'
const { Search } = Input;


export const TableCategory = () => {
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState([]);
    const [data, setData] = useState([])
    const {user} = useContext(UserContext)
    const [loading, setLoading] = useState(true)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [image, setImage] = useState(null)
    const [id, setId] = useState()

    const onSearch = (value) => {
        let input = value;
        axios.get(`${process.env.REACT_APP_API_URL}/category`,{ headers: { Authorization: "Bearer " + user.token } }).then((res) => {
            console.log(res.data);
            let dataCategory = res.data.map((el) => el);
            let filterKelas = dataCategory.filter((x) => x.name.toLowerCase().indexOf(input.toLowerCase()) !== -1);
            console.log(filterKelas);
            setData([...filterKelas]);
        });
    };

    const columns = [
        {
            title: "No",
            key: "no",

            render: (text) => {
                let index = data.indexOf(text)
                return (
                    <>{index + 1}</>
                )
            },
        },
        {
            title: 'Nama Category',
            dataIndex: 'name',
            key: 'name',
            sorter: (a,b) => {
                if (a.name < b.name) {
                    return -1
                }
                if (a.name > b.name) {
                    return 1
                }
                return 0;
            }
        },
        {
            title: 'created_at',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text) => {
                return (
                    <>
                    {new Date(text).toLocaleDateString('id-ID',{
                        year: 'numeric', 
                        month: 'numeric', 
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                        timeZone: 'Asia/Jakarta'
                    })}
                    </>
                )
            },
            sorter: (a,b) => {
                if (a.created_at < b.created_at) {
                    return -1
                }
                if (a.created_at > b.created_at) {
                    return 1
                }
                return 0;
                }
        },
        {
            title: 'updated_at',
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: (text) => {
                return (
                    <>
                    {new Date(text).toLocaleDateString('id-ID',{
                        year: 'numeric', 
                        month: 'numeric', 
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                        timeZone: 'Asia/Jakarta'
                    })}
                    </>
                )
            },
            sorter: (a,b) => {
                if (a.updated_at < b.updated_at) {
                    return -1
                }
                if (a.updated_at > b.updated_at) {
                    return 1
                }
                return 0;
                }
        },
        {
        title: 'Action',
        key: 'action',
        render: (_, record) => {
            let action = "edit"

            const confirm = async (e) => {
                await axios.delete(`${process.env.REACT_APP_API_URL}/category/${record.id}`, { headers: { Authorization: "Bearer " + user.token } })
                    .then(res => {
                        if(confirmLoading){
                            setConfirmLoading(false)
                        } else {
                            setConfirmLoading(true)
                        }
                        message.success(res.data.message, [5])
                    }).catch(err => {
                        console.log(err);
                        setConfirmLoading(false)
                    })
            };

            const cancel = (e) => {
                console.log(e);
                message.error('Click on No');
            };

            return (<Space size="middle">
            <Button type='primary' onClick={() => showModal(record.nama_kelas, action, record.id)} icon={<EditOutlined/>} />
            <Popconfirm
                title="Delete Kelas"
                description="Are you sure to delete this kelas?"
                onConfirm={confirm}
                onCancel={cancel}
                okText="Yes"
                cancelText="No"
            >
                <Button type='primary' danger icon={<DeleteOutlined/>} />                   
            </Popconfirm>
            </Space>)
        },
        },
    ];

useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/category`)
    .then(res => {
        const result = res.data.map(item => {return{...item, key: item.id}})
        setData(result)
        setLoading(false)
    }).catch(err => {
        console.log(err);
        setLoading(false)
    })   
}, [confirmLoading])

const showModal = (name,description,image, action, id) => {
    console.log(action,'ini action')
    setConfirmLoading(false)
    if(action){
        setName(name)
        setDescription(description)
        // Pastikan image adalah objek file sebelum disetel
        if (image instanceof File) {
            setImage(image);
        } else {
            setImage(null);
        }
        setId(id)
    }else{
        console.log('masuk kondisi undefined')
        setId("")
        setName("")
        setDescription("")
        setImage(null);
    }
    form.resetFields();
    setIsModalOpen(true);
};

const handleOk = async () => {
    setConfirmLoading(true);

    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    
    if (image) {
        console.log(image,'ini image')
        formData.append('photo', image);
    }

    try {
        if (!id) {
            await axios.post(`${process.env.REACT_APP_API_URL}/category`, formData, {
                headers: { Authorization: "Bearer " + user.token,'Content-Type': 'multipart/form-data' }
            });
        } else {
            await axios.patch(`${process.env.REACT_APP_API_URL}/category/${id}`, formData, {
                headers: { Authorization: "Bearer " + user.token,'Content-Type': 'multipart/form-data'}
            });
        }

        message.success('Operation successful', 5);
        setConfirmLoading(false);
        setIsModalOpen(false);
        setName('');
        setDescription('');
        setImage('');
    } catch (err) {
        console.error(err);
        Modal.warning({
            title: `${err.response.data.message}`,
        });
        setConfirmLoading(false);
        setIsModalOpen(false);
    }
};

const handleCancel = () => {
    setId("")
    setName("")
    setDescription("")
    setImage("")
    setIsModalOpen(false);
};

const handleChangeName = (e) => {
    setName(e.target.value)
}
const handleChangeDescription = (e) => {
    setDescription(e.target.value)
}

const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const beforeUpload = (file) => {
    // Implement any validation or checks for file type, size, etc.
    // Return false to cancel the upload.
    return true;
  };

  const handleFileChange = (e) => {
    console.log(e.fileList[0],'ini value handlefilechange')
    if (e.fileList.length > 0) {
      const file = e.fileList[0];
      setImage(file);
    } else {
      // Handle jika tidak ada file terpilih
      console.error("No file selected");
    }
  };
return (
    <div className="dashboard-container">
        <Skeleton loading={loading} active>
            <Typography.Title level={3}>Table Category</Typography.Title>
            <Search
                placeholder="search nama category"
                onSearch={onSearch}
                style={{ width: 250, marginRight: 20 , marginTop: 4}}
            />
            <Button onClick={showModal} style={{marginBottom: '10px'}} type='primary'>Add Category</Button>
            <Table columns={columns} dataSource={data} expandable={{
                    expandedRowRender: (record) => (
                        <>
                            <p><b> description :</b></p>
                            <p>{record.description}</p>
                            <p><b> Images : </b></p>
                            <div className='box-shadow-grey' style={{ width: '100%', display: 'flex', }}>
                                <img src={record.image} style={{width:'200px', height:'200px'}}/>
                            </div>
                        </>
                    )
                    ,
                    expandIcon: ({ expanded, onExpand, record }) =>
                        expanded ? (
                            <UpSquareOutlined style={{ fontSize: '25px' }} onClick={e => onExpand(record, e)} />
                        ) : (
                            <DownSquareOutlined style={{ fontSize: '25px' }} onClick={e => onExpand(record, e)} />
                        )
                }}
            />
        </Skeleton>

        <Modal 
            title={id ? "Edit Category" : "Add Category"} 
            open={isModalOpen} 
            okText="Submit" 
            onOk={handleOk} 
            onCancel={handleCancel}
            confirmLoading={confirmLoading}
        >
            <Form>
                <Form.Item label="Name" name="name"  rules={[{ required: true, message: 'Please enter the name' }]}>
                    <Input value ={name} onChange={handleChangeName} placeholder='Please input your Name!' />
                </Form.Item>
                <Form.Item label="Description" name="description" rules={[{ required: true, message: 'Please enter the description' }]}>
                    <Input value={description} onChange={handleChangeDescription}  placeholder='Please input your Description!' />
                </Form.Item>
                <Form.Item label="Image" name="image" valuePropName="fileList" getValueFromEvent={normFile}>
                    <Upload
                        beforeUpload={beforeUpload}
                        fileList={fileList}
                        onChange={handleFileChange}
                        >
                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                </Form.Item>
            </Form>
        </Modal>
    </div>
)
}