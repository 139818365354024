import React, { useState } from 'react';
import { useLanguage } from '../Context/LanguageContext';
import { Switch } from 'antd';

const LanguageToggle = () => {
  const { language, toggleLanguage } = useLanguage();
  const [isLeft, setIsLeft] = useState(true);

  const handleToggleChange = () => {
    toggleLanguage();
    setIsLeft((prevIsLeft) => !prevIsLeft);
  };

  return (
    <div className='switchToggle'>
      <p>Current Language: {language === 'id' ? 'Indonesian' : 'English'}</p>
      <Switch onChange={handleToggleChange} />
    </div>
  );
};

export default LanguageToggle;
