import "./style.css"
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Image, Collapse,Row, Col, Card,Button } from 'antd';
import Kustomisasi from "../../Assets/list-home-kustomisasi.svg"
import Kualitas from "../../Assets/list-home-kualitas.svg"
import Discount from "../../Assets/list-home-discount.svg"
import Like from "../../Assets/list-home-like.svg"
import MaskGroup from "../../Assets/Mask-group.svg"
import Layanan from "../../Assets/home-layanan.svg"
import FashionDesigner from "../../Assets/Fashion-Designer.svg"
import Modelling from "../../Assets/Modelling.svg"
import RumahProduksi from "../../Assets/Rumah-Produksi.png"
// import Testimoni from "../../Assets/Testimoni.svg"
import Artikel1 from "../../Assets/artikel1.svg"
import Artikel2 from "../../Assets/artikel2.png"
import Artikel3 from "../../Assets/artikel3.png"
import Artikel4 from "../../Assets/artikel4.svg"
import ChatWhatsapp from '../../Components/ChatWhatsapp';
import { useLanguage } from '../../Context/LanguageContext';
import LanguageToggle from "../../Components/LanguageToggle";
// import product3D from "../../Assets/productKain3d.svg"
import people from "../../Assets/People.png"


export const HomePage = () => {
    const { language } = useLanguage();

    console.log('Current Language:', language);
    
    const [collapsed, setCollapsed] = useState(false);

    const handleCollapseChange = () => {
        setCollapsed(!collapsed);
      };

    const items = [
        {
            key: '1',
            label: <h2>Print</h2>,
            children: 
            <p>Mewujudkan desain Anda dengan kejelasan dan kecerahan yang luar biasa 
                pada kain! Layanan Printing Kain kami di Mahesa Sublim menggunakan teknologi 
                terkini untuk menghasilkan cetakan yang tajam dan warna yang memukau. 
                Apakah Anda membutuhkan cetakan untuk seragam, tekstil promosi, atau 
                kebutuhan kain lainnya.
            </p>,
        },
        {
            key: '2',
            label: <h2>Press</h2>,
            children: 
            <p>Mewujudkan desain Anda dengan kejelasan dan kecerahan yang luar biasa 
                pada kain! Layanan Printing Kain kami di Mahesa Sublim menggunakan teknologi 
                terkini untuk menghasilkan cetakan yang tajam dan warna yang memukau. 
                Apakah Anda membutuhkan cetakan untuk seragam, tekstil promosi, atau 
                kebutuhan kain lainnya.
            </p>,
        },
        {
            key: '3',
            label: <h2>Laser Cut</h2>,
            children: 
            <p>Mewujudkan desain Anda dengan kejelasan dan kecerahan yang luar biasa 
                pada kain! Layanan Printing Kain kami di Mahesa Sublim menggunakan teknologi 
                terkini untuk menghasilkan cetakan yang tajam dan warna yang memukau. 
                Apakah Anda membutuhkan cetakan untuk seragam, tekstil promosi, atau 
                kebutuhan kain lainnya.
            </p>,
        },
    ];

    const data = [
        { id:1,title: 'Apa itu kain Twill?', content: 'Katun menjadi bahan yang sangat populer untuk pembuatan busana, aksesoris fashion hingga berbagai perlengkapan rumah tangga. Di negara-negara beriklim tropis seperti Indonesia, material katun ini sangat disukai karena membuat pemakainya senantiasa merasa nyaman.', imageUrl: Artikel1 },
        { id:2,title: 'Pengertian dan Mengenal Print Kain Lebih Dalam', content: 'Print kain merupakan proses pemberian warna dan motif pada material tekstil, perca, maupun garmen dengan cara digital printing. Prosedur mencetak corak warna itu disebut-sebut hampir sama seperti gaya penggarapan cap pakaian.', imageUrl: Artikel2 },
        { id:3,title: 'Teknik Print Kain yang Banyak Digunakan untuk Mencetak Kain', content: 'Perkembangan teknologi print textile sangat berpengaruh besar terhadap produksi industri tekstil yang semakin maju. ', imageUrl: Artikel3 },
        { id:4,title: 'Tampil Anggun dengan Bahan Kain Moscrepe', content: 'Anda pasti pernah menginginkan penampilan yang anggun dan nyaman sehari-hari, bukan? Nah, ada satu bahan kain yang bisa memberikan Anda kombinasi sempurna antara kenyamanan dan elegansi, yaitu kain moscrepe.', imageUrl: Artikel4 },
      ];

    return(
        <>
            <LanguageToggle />


            <div className="BGHome">
                <div className="gridVisi1">
                    <h1>
                        {language === 'id' ?
                            <>Menenun <span style={{fontStyle:'italic'}}>Imajinasi</span> Anda menjadi Kenyataan</> :
                            <>Weaving Your <span style={{fontStyle:'italic'}}>Imagination</span> into Reality</>
                        }
                    </h1>
                    <p>
                    {language === 'id' ? 'Percayakan kebutuhan cetak kain Anda kepada kami, dan bersama sama kita wujudkan ide-ide kreatif Anda menjadi kenyataan.' : 'Trust your fabric printing needs to us, and together we will bring your creative ideas to life.'}
                    </p>
                </div>
                <div className="gridVisi1">
                </div>
            </div>

            <div className="content">
                <h1>{language === 'id' ? 'Kenapa Mahesa Sublim?' : 'Why Choose Mahesa Sublim?'}</h1>
                <p>
                {language === 'id' ? 'Ini adalah alasan kenapa banyak klien yang mempercayakan Mahesa Sublim menjadi solusi printing kain' : 'This is why many clients trust Mahesa Sublim as their fabric printing solution'}
                </p>
            </div>

            <div className="containerCardHome">
                <Row gutter={16} style={{ marginBottom: '20px' }}>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Card style={{height:'100%'}}>
                            <Row gutter={16}>
                                <Col xs={24} sm={24} md={24} lg={7} xl={7}>
                                    <div className="mainImageHome">
                                        <img src={Kustomisasi} alt="Gambar Press" />
                                    </div>
                                    <br/>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={17} xl={17}>
                                    <div>
                                    <h2>{language === 'id' ? 'Kustomisasi sesuai kebutuhan klien' : 'Customization according to client needs'}</h2>
                                    <p> 
                                        {language === 'id' ? 'Kami menawarkan layanan custom yang mudah disesuaikan sesuai dengan keinginan klien, mulai dari pemilihan warna hingga detail terkecil dalam desain.' : 'We offer customizable services tailored to client preferences, from color selection to the smallest details in design.'}
                                    </p>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Card style={{height:'100%'}} className="cardLikeDiscount">
                            <Row gutter={16}>
                                <Col xs={24} sm={24} md={24} lg={7} xl={7}>
                                    <div className="mainImageHome">
                                        <img src={Discount} alt="Gambar Press" />
                                    </div>
                                    <br/>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={17} xl={17}>
                                    <div>
                                    <h2>{language === 'id' ? 'Harga yang sangat amat kompetitif' : 'Highly competitive pricing'}</h2>
                                    <p>
                                        {language === 'id' ? 'Harga yang sangat amat kompetitif Kami menawarkan paket-paket yang dapat disesuaikan dengan berbagai anggaran. Dengan demikian, Mahesa Sublim memastikan bahwa kualitas tinggi tetap terjangkau bagi semua kalangan.' : 'Highly competitive pricing We offer customizable packages to fit various budgets. Thus, Mahesa Sublim ensures that high quality remains affordable for everyone.'}
                                    </p>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Row gutter={16} style={{ marginBottom: '20px' }}>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Card style={{height:'100%'}} className="cardLikeDiscount">
                            <Row gutter={16}>
                                <Col xs={24} sm={24} md={24} lg={7} xl={7}>
                                    <div className="mainImageHome">
                                        <img src={Kualitas} alt="Gambar Press" />
                                    </div>
                                    <br/>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={17} xl={17}>
                                    <div>
                                    <h2>{language === 'id' ? 'Penggunaan bahan bahan berkualitas' : 'Use of high-quality materials'}</h2>
                                    <p>
                                        {language === 'id' ? 'Produk Mahesa Sublim tidak hanya tentang estetika, tetapi juga tentang kualitas yang luar biasa. Menggunakan bahan berkualitas tinggi yang memberikan hasil cetak yang tajam dan tahan lama.' : 'Mahesa Sublim products are not just about aesthetics, but also about exceptional quality. Using high-quality materials that provide sharp and durable print results.'}
                                    </p>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Card style={{height:'100%'}} className="cardLikeDiscount2">
                            <Row gutter={16}>
                                <Col xs={24} sm={24} md={24} lg={7} xl={7}>
                                    <div className="mainImageHome">
                                        <img src={Like} alt="Gambar Press" />
                                    </div>
                                    <br/>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={17} xl={17}>
                                    <div>
                                    <h2>{language === 'id' ? 'Produk dipercaya oleh banyak klien' : 'Products trusted by many clients'}</h2>
                                    <p>
                                        {language === 'id' ? 'Dalam industri cetak kain, Mahesa Sublim telah membangun reputasi yang kuat. Kepercayaan yang diberikan oleh klien kami mencerminkan dedikasi kami untuk memberikan layanan terbaik.' : 'In the fabric printing industry, Mahesa Sublim has built a strong reputation. The trust given by our clients reflects our dedication to providing the best service.'}
                                    </p>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </div>
            
            
            <div className="ContainerAlurKerja">
                <Row gutter={[16, 16]}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                        <div className="gridAlurKerja">
                            <div className="mainImageHome1">
                                <img src={MaskGroup} alt="Gambar MaskGroup" />
                            </div>
                        </div>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12}}>
                        <div className="gridAlurKerja">
                            <h2>{language === 'id' ? 'Tentang Kami' : 'About Us'}</h2>
                            <h1>Mahesa Sublim</h1>
                            <p>
                                {language === 'id' ?
                                'Di Mahesa Sublim, kami tidak hanya sekadar menyediakan layanan cetak kain, tetapi juga membawa pengalaman yang berbeda dalam dunia cetak dengan Metode Sublimasi Printing & DTF Printing. Keahlian kami terletak pada kemampuan kami untuk menghadirkan warna-warna yang hidup, detail yang presisi, dan hasil cetak yang tahan lama.' :
                                'At Mahesa Sublim, we not only provide fabric printing services but also bring a unique experience to the printing world with Sublimation Printing & DTF Printing methods. Our expertise lies in our ability to deliver vibrant colors, precise details, and durable print results.'
                                }
                            </p>
                            <br />
                            <Link to={`/tentang-kami`}>
                                <Button className="buttonStyleHome">{language === 'id' ? 'Lihat Detail' : 'See Details'}</Button>
                            </Link>
                        </div>
                    </Col>
                </Row>
            </div>


            <div className={`ContainerLayanan ${collapsed ? 'collapsed' : ''}`}>
                <h1>{language === 'id' ? 'Layanan Kami' : 'Our Services'}</h1>
                <p style={{ color: '#868686' }}>
                    {language === 'id' ?
                    'Selamat datang di Mahesa Sublim, tempat di mana kain-kain Anda diubah menjadi karya seni yang luar biasa. Dengan Metode Sublimasi Printing & DTF Printing, kami menghadirkan solusi cetak kain yang tak tertandingi, memenuhi kebutuhan printing Anda dengan keunggulan dan kreativitas.' :
                    'Welcome to Mahesa Sublim, where your fabrics are transformed into extraordinary works of art. With Sublimation Printing & DTF Printing methods, we deliver unparalleled fabric printing solutions, meeting your printing needs with excellence and creativity.'
                    }
                </p>
                <br/>
                <br/>
                <div class="grid-container-layanan">
                    <div className={`grid-item-layanan-1 ${collapsed ? 'collapsed' : ''}`}>
                        <Collapse accordion items={items} bordered={false} style={{border:'1px white solid'}} onChange={handleCollapseChange}
                         />
                    </div>
                    <div class="grid-item-layanan-2">
                        <div className="mainImage">
                            <img src={Layanan} alt="Gambar Press" />
                        </div>
                    </div>
                </div>
            </div>



            <div className="ContainerKerjaSamaCLient">
                <div className="gridKerjaSamaClient gridKerjaSamaClient-1">
                    <h1>
                        {language === 'id' ? 'Mahesa Sublim Telah Bekerjasama dengan' : 'Mahesa Sublim has collaborated with'}{' '}
                        <span style={{ fontWeight: '1000' }}>{language === 'id' ? '500+ Klien' : '500+ Clients'}</span>{' '}
                        {language === 'id' ? 'dari Berbagai Profesi' : 'from Various Professions'}
                    </h1>
                </div>

                <div className="gridKerjaSamaClient gridKerjaSamaClient-2">
                    <div className="gridThreeCard">
                        <div className="mainImage">
                            <img src={FashionDesigner} alt="Gambar FashionDesigner" />
                        </div>
                    </div>
                    <div className="gridThreeCard">
                        <div className="mainImage">
                            <img src={Modelling} alt="Gambar Modelling" />
                        </div>
                    </div>
                    <div className="gridThreeCard">
                        <div className="mainImage">
                            <img src={RumahProduksi} alt="Gambar RumahProduksi" />
                        </div>
                    </div>
                </div>
            </div>


            <div className="ContainerMakeIdeas">
                <div className="ContainerContentMakeIdeas">
                    <div className="gridMakeIdeas gridMakeIdeas-1">
                    <div class="cardMakeIdeas">
                        <div class="leftGridCard">
                            <h1>500 +</h1>
                            <p>{language === 'id' ? 'Klien' : 'Clients'}</p>
                        </div>
                        <div class="rightGridCard">
                            <h1>20</h1>
                            <p>{language === 'id' ? 'Jenis Kain Berbeda' : 'Different Types of Fabric'}</p>
                        </div>
                    </div>
                    <hr/>
                    <div class="cardMakeIdeas">
                        <div class="leftGridCard">
                            <h1>4.8/5.0</h1>
                            <p>{language === 'id' ? 'Kepuasan pelanggan' : 'Customer Satisfaction'}</p>
                        </div>
                        <div class="rightGridCard">
                            <h1>1,200,000m</h1>
                            <p>{language === 'id' ? 'Kain terjual' : 'Fabrics Sold'}</p>
                        </div>
                    </div>
                    <hr/>
                    <div class="cardMakeIdeas">
                        <div class="leftGridCard">
                            <h1>100+</h1>
                            <p>{language === 'id' ? 'Proyek reatif' : 'Creative Projects'}</p>
                        </div>
                        <div class="rightGridCard">
                            <h1>ISO 9001</h1>
                            <p>{language === 'id' ? 'Sertifikasi' : 'Certification'}</p>
                        </div>
                    </div>
                    <hr/>
                    </div>

                    <div className="gridMakeIdeas gridMakeIdeas-2">
                    <h1>{language === 'id' ? 'Wujudkan Ide-ide Anda Menjadi Kenyataan' : 'Make Your Ideas Into Reality'}</h1>
                    <p>
                        {language === 'id' ?
                        'Dari hasil penjualan mengesankan hingga dedikasi kami terhadap inovasi dan kepuasan pelanggan, Mahesa Sublim adalah pilihan utama untuk kebutuhan cetak kain Anda. Bergabunglah dengan kami, dan mari ciptakan karya-karya kain yang menginspirasi bersama!' :
                        'From impressive sales results to our dedication to innovation and customer satisfaction, Mahesa Sublim is the top choice for your fabric printing needs. Join us, and let\'s create inspiring fabric works together!'
                        }
                    </p>
                    </div>
                </div>
            </div>



            <div className="ContainerTestimoni">
                <div className="ContainerContentTestimoni">
                    <div className="gridTestimoni gridTestimoni-1">
                        <h2>{language === 'id' ? 'Testimoni' : 'Testimonials'}</h2>
                        <h1>
                            {language === 'id' ?
                            '“Hasil Printing Mahesa Sublim selalu mencapai ekspektasi. Bagi saya, kualitas kain dan printing sangat penting dalam bisnis fashion dan Mahesa Sublim bisa mewujudkan itu.”' :
                            '“The printing results from Mahesa Sublim always meet expectations. For me, the quality of fabric and printing is crucial in the fashion business, and Mahesa Sublim can achieve that.”'
                            }
                        </h1>
                        <br/><br/>
                        <h3>Kristen Stewart - Fashion Designer (Putra Mutiara Jaya)</h3>
                        <br/><br/>
                        <hr/>
                    </div>

                    <div className="gridTestimoni gridTestimoni-2">
                        <div className="imageTestimoni"/>
                    </div>
                </div>
            </div>



            <div className="ContainerArtikel">
                <div className="contentArtikel">
                    <h1>{language === 'id' ? 'Artikel' : 'Articles'}</h1>
                    <p>
                        {language === 'id' ?
                        'Mahesa Sublim bukan sekadar penyedia layanan cetak kain; kami adalah penghubung antara ide kreatif dan realitas yang mencengangkan.' :
                        'Mahesa Sublim is not just a fabric printing service provider; we are the bridge between creative ideas and stunning reality.'
                        }
                    </p>
                </div>
                <div className="site-card-wrapper">
                    <Row gutter={16}>
                        {data.map((item, index) => (
                        <Col key={index} xs={24} sm={12} md={8} lg={6}>
                            <Card bordered={true} className="CardArtikel">
                                <img src={item.imageUrl} alt={item.title} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                <div>
                                    <h2>{item.title}</h2>
                                    <p>{item.content}</p>
                                </div>
                                <div className="linkCard">
                                    <Link to={`/detail-artikel/${item.id}`}>
                                    {language === 'id' ? 'Lanjutkan membaca' : 'Continue reading'}
                                    </Link>
                                </div>
                            </Card>
                        </Col>
                        ))}
                    </Row>
                </div>
            </div>

            <div className="product3D">
                <div className="contener3d">
                    <div className="product3DDesain">
                        <Row gutter={[16, 16]}>
                            <Col xs={{ span: 24, order: 2 }} sm={{ span: 24, order: 2 }} md={{ span: 16, order: 1 }} lg={{ span: 16, order: 1 }} className="gridDesain2">
                                <div className="product3DDesainp gridDesain2" style={{ marginRight: '5%' }}>
                                    <h1>Produk anda belum pakai stiker kain 3D ?</h1>
                                    <p>
                                        Kunjungi website Axelindo Labels. Tempat di mana visi Anda disampaikan lewat Brandlogo Anda.
                                        Biarkan Brand Anda berbicara dan jadikan Brand Anda tak terlupakan.
                                    </p>
                                <Link to={`https://www.axelsindolabels.id`}>
                                    <Button className="buttonStyleHome2">Kunjungi</Button>
                                </Link>
                                </div>
                            </Col>
                            <Col xs={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} md={{ span: 8, order: 2 }} lg={{ span: 8, order: 2}} className="gridDesain2">
                                <div className="grid-item">
                                    <div className="mainImage3d">
                                        <img src={people} alt="Gambar Press" />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>


            <ChatWhatsapp/>
        </>
    )
}