import "./style.css"
import { Row, Col } from 'antd';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom'
import ChatWhatsapp from '../../Components/ChatWhatsapp';
import { useLanguage } from '../../Context/LanguageContext';
import LanguageToggle from "../../Components/LanguageToggle";
import JenisKainImage from "../../Assets/JenisKain1.png"
import Baloteli from "../../Assets/baloteli.png"
import Voal from "../../Assets/voal.png"
import Sifon from "../../Assets/sifon.png"
import Silk from "../../Assets/silk.png"


export const JenisKain = () => {
    let navigate = useNavigate()
    const { language } = useLanguage();

    const data = [
        {
            id: 1,
            image: Baloteli,
            name: "Baloteli",
        },
        {
            id: 2,
            image: Voal,
            name: "Voal",
        },
        {
            id: 3,
            image: Sifon,
            name: "Sifon",
        },
        {
            id: 4,
            image: Silk,
            name: "Silk",
        },
        {
            id: 5,
            image: JenisKainImage,
            name: "Scuba",
        },
        {
            id: 6,
            image: JenisKainImage,
            name: "Maxmara",
        },
    ]


    return (
        <>
            <LanguageToggle />

            <div className="ContainerBG">
                <div className="BGJenisKain">
                    <h1>{language === 'id' ? 'Jenis Kain' : 'Types of Fabric'}</h1>
                </div>
            </div>

            <div className="contentJenisKain">
                <h1>{language === 'id' ? 'Jenis Kain di Mahesa Sublim' : 'Types of Fabric at Mahesa Sublim'}</h1>
                <p>
                {language === 'id'
                    ? 'Kami menyediakan berbagai pilihan jenis bahan kain printing yang dapat Anda pilih. Bagi Anda yang memiliki pertanyaan seputar bahan kain printing, silahkan hubungi kami, atau Anda bisa membeli sampel print yang kami miliki.'
                    : 'We provide a variety of options for printing fabric materials that you can choose from. For those of you who have questions about printing fabric materials, please contact us, or you can buy the sample prints that we have.'}
                </p>
            </div>

            <div className="site-card-wrapper" style={{ margin: '0 5%' }}>
                <Row gutter={[16, 16]}>
                    {data.map((item, index) => (
                    <Col key={index} xs={24} sm={12} md={8} lg={8}>
                        <Link to={`/detail-kain/${item.id}`}>
                            <div 
                                style={{
                                    overflow: 'hidden',
                                    margin: '10px 10px 20px 10px'
                                }}
                            >
                                <img src={item.image} alt={item.name} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                <h1>{item.name}</h1>
                            </div>
                        </Link>
                    </Col>
                    ))}
                </Row>
            </div>



            <ChatWhatsapp/>
        </>
    )
}