import "./style.css"
import { Row, Col,Form,Input,message,Modal,Button } from 'antd';
import { useState, useContext, useEffect } from 'react'
import axios from 'axios'
import { UserContext } from "../../Context/UserContext";
import { logout } from '../../Helper/utils/Function';
import ChatWhatsapp from '../../Components/ChatWhatsapp';
import { useLanguage } from '../../Context/LanguageContext';
import LanguageToggle from "../../Components/LanguageToggle";

const { TextArea } = Input;

export const Kontak = ({ google }) => {
    const { language } = useLanguage();

    const [data, setData] = useState([])
    const { user,setUser } = useContext(UserContext)
    const [loading, setLoading] = useState(true)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [categories, setCategories] = useState([])
    const [id, setId] = useState()
    let defaultInput = { name: "", description: "", price: "", discount: "", stock: "", category_id: "" }
    const [, setInput] = useState(defaultInput)


    useEffect(() => {
        const fetchData = () => {
            axios.get(`${process.env.REACT_APP_API_URL}/product`)
                .then(res => {
                    const result = res.data.data.map(item => { return { ...item, key: item.id } })
                    setData(result)
                    setLoading(false)
                    setInput({
                        name: result.name,
                        description: result.description,
                        price: result.price,
                        stock: result.stock,
                        discount: result.discount,
                        category_id: result.category_id,
                    })
                }).catch(err => {
                    console.log(err);
                    setLoading(false)
                })
        }
        const fetchDataCategory = () => {
            axios.get(`${process.env.REACT_APP_API_URL}/category`)
                .then(res => {
                    const result = res.data.data
                    setCategories(result)
                    setLoading(false)
                }).catch(err => {
                    console.log(err);
                    setLoading(false)
                })
        }
        fetchData()
        fetchDataCategory()
    }, [confirmLoading])

    const showModal = (action, id) => {
        if (action) {
            setId(id)
        } else {
            setId("")
            form.resetFields()
        }
        setIsModalOpen(true);
    };


    const handleOk = async (value) => {
        console.log(id)
        let { name, description, price, stock, discount, category_id } = value
        setConfirmLoading(true)
        if (!id) {
            await axios.post(`${process.env.REACT_APP_API_URL}/product`, { name, description, price: Number(price), stock: Number(stock), discount: Number(discount), category_id }, { headers: { Authorization: "Bearer " + user.token } })
                .then(res => {
                    message.success(res.data.message, [5])
                    setConfirmLoading(false)
                    setIsModalOpen(false);
                    setInput(defaultInput)
                    form.resetFields()
                    window.location.reload()
                }).catch(err => {
                    console.log(err.response.data.error);
                    setConfirmLoading(false)
                    Modal.warning({
                        title: `${err.response.data.error}`
                    });
                    if (err.response.data.error === "invalid token") {
                        console.log('harus logout');
                        logout({setUser, status: "expired"})
                    }
                })
        } else {
            await axios.patch(`${process.env.REACT_APP_API_URL}/product/${id}`, {name, description, price: Number(price), stock: Number(stock), discount: Number(discount), category_id}, { headers: { Authorization: "Bearer " + user.token } })
                .then(res => {
                    message.success(res.data.message, [5])
                    setConfirmLoading(false)
                    setIsModalOpen(false);
                    window.location.reload()
                    form.resetFields()
                }).catch(err => {
                    console.log(err);
                    console.log("masuk kesini error edit")
                    console.log(value)
                    setConfirmLoading(false)
                    setIsModalOpen(false);
                    Modal.warning({
                        title: `${err.response.data.message}`
                    });
                    form.resetFields()
                })
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        form.resetFields()
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo.values);
        Modal.warning({
            title: `${errorInfo.values.Failed}`
        });
    };
    const [form] = Form.useForm();

    return (
        <>
            <LanguageToggle />


            <div className="ContainerBG">
                <div className="BGKontak">
                    <h1>{language === 'id' ? 'Informasi Kontak' : 'Contact Information'}</h1>
                </div>
            </div>

            <div className="containerKontak">
                <Row gutter={[16, 16]}>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                        <div className="grid-item-kontak">
                            <h1>{language === 'id' ? 'Kontak' : 'Contact'}</h1>
                            <p>
                            {language === 'id' ? 'Hubungi Mahesa Sublim sekarang untuk mendapatkan pengalaman cetak kain yang tak tertandingi. Kami siap menjadi bagian dari perjalanan kreatif Anda dan menghadirkan hasil cetak yang mengesankan.' : 'Contact Mahesa Sublim now to experience unrivaled fabric printing. We are ready to be part of your creative journey and deliver impressive print results.'}
                            </p>
                            <Form form={form} name="basic" labelCol={{ span: 5, }}
                                wrapperCol={{ span: '60%', }} initialValues={{ remember: true, }}
                                onFinishFailed={onFinishFailed} autoComplete="off">
                                <Form.Item>
                                    <Input placeholder='Nama Lengkap' style={{backgroundColor: '#efefef'}} />
                                </Form.Item>
                                <Form.Item>
                                    <Input placeholder='Alamat Email' style={{backgroundColor: '#efefef'}}/>
                                </Form.Item>
                                <Form.Item>
                                    <Input placeholder='Subjek' style={{backgroundColor: '#efefef'}} />
                                </Form.Item>
                                <Form.Item>
                                    <TextArea placeholder='Pesan' style={{backgroundColor: '#efefef', height:'200px'}} />
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        size="middle"
                                        style={{ width: "100%",backgroundColor: '#333', color: '#fff' }}
                                        onClick={handleOk}
                                    >
                                        {language === 'id' ? 'Kirim Pesan' : 'Send Message'}
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                        <div className="grid-item-kontak" style={{ marginLeft: '5%' }}>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15841.86984817053!2d107.62657469999999!3d-6.954054199999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e68e90a23fd0cdf%3A0x23d6ed39d0d7b07c!2sMahesa%20Sublim%20-%20Jasa%20Print%20Kain%20Bandung!5e0!3m2!1sid!2sid!4v1705997149950!5m2!1sid!2sid"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" style={{width: '100%', height: '550px'}}>

                        </iframe>
                        </div>
                    </Col>
                </Row>
            </div>


            <ChatWhatsapp/>
        </>
    )
}
