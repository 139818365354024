import { Menu, Layout, Row, Col } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../Context/UserContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Logo from "../../Assets/Logo.png"

const { Header } = Layout;

export const CustomHeader = () => {
  const { user, setUser } = useContext(UserContext);
  const [currentUser, setCurrentUser] = useState();
  const navigate = useNavigate();

  const role = user?.role === "USER" ? "user" : "admin";

  useEffect(() => {
    if (user) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/profile`, {
          headers: { Authorization: "Bearer " + user?.token },
        })
        .then((res) => {
          setCurrentUser(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line
  }, [user?.id]);

  const handleLogout = (e) => {
    localStorage.removeItem("user");
    setUser(null);
    navigate("/login/user");
  };

  const items = [
    {
      label: (
        <div
          onClick={() => {
            navigate("/dashboard");
          }}
        >
          Dashboard
        </div>
      ),
      key: "/dashboard",
    },
    {
      label: <div onClick={handleLogout}>Logout</div>,
      key: "/logout",
    },
  ];

  const headerMenu = [
    {
      label: "Beranda",
      key: "/",
    },
    {
      label: "Tentang Kami",
      key: "/tentang-kami",
    },
    {
      label: "Jenis Kain",
      key: "/jenis-kain",
    },
    {
      label: "Layanan Kami",
      key: "/layanan-kami",
    },
    {
      label: "Artikel",
      key: "/artikel",
    },
    {
      label: "Kontak",
      key: "/kontak",
    },
  ];

  const handleMenu = (props) => {
    navigate(props.key);
  };

  return (
    <Layout>
    <Layout.Header className="nav-header" >
        <Row>
            <Col span={8}>
                <Row gutter={16}>
                    {/* <Col span={9}>
                        <div className="itemLogoHeader">
                            <img src={Logo} alt="Gambar Press" style={{margin: 'auto'}} />
                        </div>
                    </Col>
                    <Col span={15}>
                        <h3>Mahesa Sublim</h3>
                    </Col> */}
                    <Col span={24}>
                    <div className="logo">
                        <h3 className="brand-font" style={{display:'flex'}}>
                        <div className="itemLogoHeader">
                            <img src={Logo} alt="Gambar Press" style={{margin: 'auto'}} />
                        </div>
                            <h3>Mahesa Sublim</h3>
                        </h3>
                    </div>
                    </Col>
                </Row>
            </Col>
            <Col span={1}>

            </Col>
            <Col span={15}>
            <Menu
                style={{ fontWeight: "bold" }}
                theme="light"
                mode="horizontal"
                defaultSelectedKeys={["2"]}
                items={headerMenu}
                onClick={handleMenu}
                overflowedIndicator={<MenuOutlined />}
            />
            </Col>
            {/* <Col span={4}>
            <Row justify="end" align="middle">
                {!user ? (
                <Space>
                    <Button type="primary">
                    <Link to="/login/user">Login</Link>
                    </Button>
                    <Button type="primary">
                    <Link to="/register">Register</Link>
                    </Button>
                </Space>
                ) : (
                <Dropdown
                    menu={{
                    items,
                    }}
                >
                    <Space>
                    <Avatar
                        src={currentUser?.profile_url}
                        icon={<UserOutlined />}
                    />
                    Hello {currentUser?.fullname ? currentUser.fullname : "Anonymous"}
                    <DownOutlined />
                    </Space>
                </Dropdown>
                )}
            </Row>
            </Col> */}
        </Row>
    </Layout.Header>
    </Layout>
  );
};
