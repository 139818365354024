import {
    Alert,
    Button,
    Col,
    Form,
    Input,
    Row,
    Space,
    Typography,
    Carousel,
    Image,
  } from "antd";
  import axios from "axios";
  import { useContext, useState } from "react";
  import { useNavigate, useParams } from "react-router-dom";
  import { UserContext } from "../../Context/UserContext";
  // import macBook from "../../Images/macbook.jpg";
  // import iphone from "../../Images/iphone.jpg";
  // import appleWatch from "../../Images/applewatch.jpg";
  
  const contentStyle = {
    height: "400px",
    objectFit: "cover",
  };
  
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 4,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 20,
      },
    },
    labelAlign: "left",
  };
  
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 24,
        offset: 4,
      },
    },
  };
  
  export const Login = () => {
    const navigate = useNavigate();
    const [errorAlert, setErrorAlert] = useState(false);
    const { setUser } = useContext(UserContext);
    const [loaderButton, setLoaderButton] = useState(false);
    const { role } = useParams();
  
    const onFinish = async (value) => {
      setLoaderButton(true);
      setErrorAlert(false);
      try {
        axios
          .post(`${process.env.REACT_APP_API_URL}/login`, value)
          .then((res) => {
            let currentUser = {
              id: res.data.data.id,
              email: res.data.data.email,
              role: res.data.data.role,
              token: res.data.token,
            };
            setUser(currentUser);
            localStorage.setItem("user", JSON.stringify(currentUser));
            setLoaderButton(false);
            navigate("/dashboard");
          })
          .catch((err) => {
            console.log(err);
            setErrorAlert(true);
            setLoaderButton(false);
          });
      } catch (error) {
        console.log(error);
        setErrorAlert(true);
        setLoaderButton(false);
      }
    };
  
    return (
      <div className="container">
        <Row
          align="middle"
          justify="center"
          gutter={24}
          style={{
            backgroundColor: "#fff",
            marginTop: "100px",
            padding: "0px",
            height: "400px",
          }}
        >
          <Col span={10} style={{ padding: "0px" }}>
            {/* <Carousel autoplay>
              <div>
                <Image style={contentStyle} src={macBook} />
              </div>
              <div>
                <Image style={contentStyle} src={iphone} />
              </div>
              <div>
                <Image style={contentStyle} src={appleWatch} />
              </div>
            </Carousel> */}
          </Col>
          <Col span={14}>
            {errorAlert && (
              <Alert
                message="Incorrect email or password"
                type="error"
                closable
              />
            )}
            <Row justify="center" align="middle">
              <Col span={24}>
                <Row justify="center">
                  <Typography.Title
                    style={{ margin: "0px 0px 30px" }}
                    level={3}
                  >{`Login ${
                    role === "user" ? "User" : "Admin"
                  }`}</Typography.Title>
                </Row>
              </Col>
              <Col span={24}>
                <div>
                  <Form
                    {...formItemLayout}
                    name="login"
                    onFinish={onFinish}
                    scrollToFirstError
                  >
                    <Form.Item
                      name="email"
                      label="E-mail"
                      rules={[
                        {
                          required: true,
                          message: "Please input your email!",
                        },
                        {
                          type: "email",
                          message: "Please input valid email!",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input />
                    </Form.Item>
  
                    <Form.Item
                      name="password"
                      label="Password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input.Password />
                    </Form.Item>
  
                    <Form.Item {...tailFormItemLayout}>
                      <Row>
                        <Col>
                          <Space>
                            <Button
                              type="primary"
                              htmlType="submit"
                              loading={loaderButton}
                            >
                              Submit
                            </Button>
                            {role === "user" ? (
                              <Button
                                type="link"
                                onClick={() => navigate("/login/admin")}
                              >
                                or login as Admin
                              </Button>
                            ) : (
                              <Button
                                type="link"
                                onClick={() => navigate("/login/user")}
                              >
                                or login as User
                              </Button>
                            )}
                          </Space>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Form>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  };
  