import { Modal } from "antd"


export const logout = ({setUser, status})=>{
 if (status === "expired") {
        Modal.error({
            title:`Login Expired`,
            content:`Please Login Again to Continue`,
            okText : 'Oke'
        })
    }
    setUser(null)
    localStorage.clear()

}
