import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import { CustomFooter } from '../Footer';
import { CustomHeader } from '../Header';
import { Sidebar } from '../Sidebar';

const { Content } = Layout;

export const Main = () => {
    
    return (
        <Layout>
        <CustomHeader/>
        <Layout>
            <Sidebar/>
            <Content>
                <Outlet/>
            </Content>
        </Layout>
        <CustomFooter/>
        </Layout>
    )
}