import "./style.css"
import { useNavigate } from "react-router-dom";
import { useLanguage } from '../../Context/LanguageContext';
import LanguageToggle from "../../Components/LanguageToggle";
import Whatsapp from '../../Assets/ButtonWhatsapp.svg'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import detailartikel1 from "../../Assets/detailartikel1.png"
import detailartikel2 from "../../Assets/detailartikel2.png"
import detailartikel3 from "../../Assets/detailartikel3.png"
import Artikel1 from "../../Assets/BGDetailArtikel1.png"

export const DetailArtikel1 = () => {
    const { language } = useLanguage();
    const navigate = useNavigate()
    
    const handleImageClick = () => {
        const phoneNumber = '+6287781819779';
        window.open(`https://wa.me/${phoneNumber}`, '_blank');
    };

    const artikel = 
        {
            id: 1,
            image: Artikel1,
            name: "Apa itu Kain Twill?",
            image_karakteristik: detailartikel1,
            karakteristik: "Mengenal Karakteristik Kain Katun Twill",
            karakteristik_description: "Katun menjadi bahan yang sangat populer untuk pembuatan busana, aksesoris fashion hingga berbagai perlengkapan rumah tangga. Di negara-negara beriklim tropis seperti Indonesia, material katun ini sangat disukai karena membuat pemakainya senantiasa merasa nyaman.Katun twill merupakan salah satu jenis kain yang dibuat dari bahan baku benang katun dan ditenun menggunakan anyaman keper/twill.Perkembangan teknologi tenun memunculkan begitu banyak teknik pembuatan kain modern, salah satunya yaitu pertenunan kain dengan anyaman twill yang begitu unik. Hingga akhirnya diproduksilah kain katun menggunakan teknik tenunan twill sehingga menghasilkan kain yang memiliki pola garis diagonal di permukaan kainnya.",
            image_title: detailartikel2,
            title: "Apa itu Twill?",
            title_description: "Twill, mungkin nama bahan ini belum begitu akrab di telinga banyak orang, tetapi ketika kita membicarakan dunia fashion, twill adalah salah satu bahan yang memiliki peran ikonik. Twill bukanlah sembarang kain, tetapi merupakan salah satu yang telah memainkan peran penting dalam sejarah mode.Keunikan twill terletak pada tekstur diagonalnya yang khas, yang memberikan tampilan yang berbeda dari kain lainnya. Pola ini menciptakan tampilan yang elegan dan mendalam pada kain, yang menjadikannya pilihan yang sangat diminati dalam pembuatan pakaian high-end.Selain dari pola diagonal yang memukau, twill juga dikenal karena ketahanannya yang luar biasa. Ini adalah bahan yang awet dan mampu bertahan lama dari segi kualitas, sehingga pakaian yang terbuat dari twill dapat menjadi investasi yang baik dalam lemari pakaian Anda. Selain itu, kemampuan pewarnaan twill yang sangat baik membuatnya cocok untuk berbagai gaya dan warna yang tahan lama",
            image_bahan: detailartikel3,
            bahan: "Bahan Twill",
            bahan_description: "Bahan twill sebenarnya ada banyak, karena teknik tenun twill ini tidak saja berasal dari bahan kapas saja, tetapi bisa berasal dari wol dan serat-serat lainnya sehingga menghasilkan berbagai jenis kain twill. Kain twill yang paling populer adalah kain untuk bahan celana Chinos yang digunakan untuk kaum laki-laki. Kualitas kain twill tergantung dari bahan pembuatannya, jika bahan dasar pembuatan kain twill bagus maka akan menghasilkan sebuah kain yang berkualitas bagus juga. Kain twill memiliki ketebalan yang bagus dan ringan, sehingga kain ini banyak digunakan dalam bentuk berbagai macam model busana seperti jaket, celana, baju, dll.Namun, bahan twill yang sudah banyak orang persepsikan sekarang ini pada umumnya adalah kain katun twill. Kain katun twill memiliki sebutan lain yang populer yaitu kain katun Cina. Kain katun twill sendiri memilki beberapa jenis dengan kualitas yang berbeda-beda. Sehingga juga memiliki fungsi yang berbeda-beda dalam penggunaannya. Perbedaan kualitas dihasilkan dari perbedaaan kualitas bahan juga. Jika kain katun twill kualitas premium tentu dari bahan kapas 100 %, sedangkan kualitas dibawahnya dari bahan kaps campuran dengan serat sintetis.",
        }

    return(
        <>
            <LanguageToggle />

            <div className="ContainerBG">
                <div style={{background: `url(${artikel.image})`,backgroundSize:'cover',backgroundRepeat:'no-repeat',backgroundPosition:'center'}} className="BGDetailArtikel" >
                    <h1>{artikel.name}</h1>
                </div>
            </div>
            
            <div className="ContainerDetailArt">
                <div className="ContentDetailArt">
                    <h1>{artikel.karakteristik}</h1>
                    <div className="mainImageDetailArt">
                        <img src={artikel.image_karakteristik} alt={`Gambar ${artikel.name}`} />
                    </div>
                    <p>{artikel.karakteristik_description}</p>
                </div>
            </div>

            <div className="ContainerDetailArt">
                <div className="ContentDetailArt">
                    <h1>{artikel.title}</h1>
                    <div className="mainImageDetailArt">
                        <img src={artikel.image_title} alt={`Gambar ${artikel.title}`} />
                    </div>
                    <p>{artikel.title_description}</p>
                </div>
            </div>

            <div className="ContainerDetailArt">
                <div className="ContentDetailArt">
                    <h1>{artikel.bahan}</h1>
                    <div className="mainImageDetailArt">
                        <img src={artikel.image_bahan} alt={`Gambar ${artikel.bahan}`} />
                    </div>
                    <p>{artikel.bahan_description}</p>
                </div>
            </div>
        </>
    )
}