import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../Context/UserContext";


export const ProtectedAuth = ({ children }) => {
  const { user } = useContext(UserContext)
  if (user) {
    return <Navigate to="/not-found" />;
  }
  return children;
};