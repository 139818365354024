import "./style.css"
import React from 'react';
import { Link } from "react-router-dom"
import ChatWhatsapp from '../../Components/ChatWhatsapp';
import { useLanguage } from '../../Context/LanguageContext';
import LanguageToggle from "../../Components/LanguageToggle";
import Artikel1 from "../../Assets/Artikel-1.svg"
import Artikel2 from "../../Assets/Artikel-2.png"
import Artikel3 from "../../Assets/Artikel-3.png"
import Artikel4 from "../../Assets/Artikel-4.svg"

export const Artikel = () => {
    const { language } = useLanguage();

    const data = [
        {
          id: 1,
          imageUrl: Artikel1,
          title: 'Apa itu kain Twill?',
          content: 'Katun menjadi bahan yang sangat populer untuk pembuatan busana,aksesoris fashion hingga berbagai perlengkapan rumah tangga. Di negara-negara beriklim tropis seperti Indonesia, material katun ini sangat disukai karena membuat pemakainya senantiasa merasa nyaman',
        },
        {
          id: 2,
          imageUrl: Artikel2,
          title: 'Pengertian dan Mengenal Print Kain Lebih Dalam',
          content: 'Print kain merupakan proses pemberian warna dan motif pada material tekstil, perca, maupun garmen dengan cara digital printing. Prosedur mencetak corak warna itu disebut-sebut hampir sama seperti gaya penggarapan cap pakaian. Sebelum masuk ke proses print kain, kamu perlu menyiapkan desain atau motif terlebih dahulu, kemudian melakukan cetak corak warna pada bahan pilihan tersebut.',
        },
        {
            id: 3,
            imageUrl: Artikel3,
            title: 'Teknik Print Kain yang Banyak Digunakan untuk Mencetak Kain',
            content: 'Perkembangan teknologi print textile sangat berpengaruh besar terhadap produksi industri tekstil yang semakin maju. Saat ini, Anda banyak menemukan motif dan desain kain dengan berbagai ukuran yang menarik dan tampak stylish. Beberapa hal itu mungkin tersedia dengan adanya perkembangan mesin print kain yang lebih canggih. Mulai dari bahan mentah seperti baju, tas, celana dan lainnya dapat dibuat menggunakan teknologi print kain.',
          },
          {
            id: 4,
            imageUrl: Artikel4,
            title: 'Tampil Anggun dengan Bahan Kain Moscrepe',
            content: 'Anda pasti pernah menginginkan penampilan yang anggun dan nyaman sehari-hari, bukan? Nah, ada satu bahan kain yang bisa memberikan Anda kombinasi sempurna antara kenyamanan dan elegansi, yaitu kain moscrepe. Artikel ini akan membantu Anda memahami Bahan Moscrepe dan mengapa bahan ini sangat cocok untuk busana kasual Anda.',
          },
      ];

    return (
        <>
            <LanguageToggle />

            <div className="ContainerBG">
                <div className="BGArt">
                    <h1>{language === 'id' ? 'Artikel' : 'Articles'}</h1>
                </div>
            </div>

            <div className="ContainerArt">
                {data.map((item) => (
                    <div key={item.id} className="ContentArt">
                        <div className="mainImageArt">
                            <img src={item.imageUrl} alt={`Gambar ${item.title}`} />
                        </div>
                        <h1>{item.title}</h1>
                        <p>{item.content}</p>
                        <Link to={`/detail-artikel/${item.id}`}><h2>{language === 'id' ? 'Lanjutkan membaca' : 'Continue Reading'}</h2></Link>
                    </div>
                ))}
            </div>
            

            <ChatWhatsapp/>
        </>
    )
}