import "./style.css"
import { Layout, theme, Image, Row, Col, Typography, Button, Space } from 'antd';
// import logo3d from '../../Images/logo-iphone3d.svg'
import { MailOutlined, PhoneOutlined, InstagramFilled } from '@ant-design/icons'
import Logo from "../../Assets/Logo.png"
import { useLanguage } from '../../Context/LanguageContext';

const { Title } = Typography
const { Footer } = Layout;

export const CustomFooter = () => {
    const { token } = theme.useToken()
    const { language } = useLanguage();

    return (
        <>
        <Footer className='custom-footer'>
            <div className='marginFooter'>
                <Row gutter={16}>
                    <Col xs={24} sm={12} md={10} lg={10} xl={10}>
                        <div className="gridFooter">
                            <Row gutter={16} align="middle">
                                <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                                    <div className="itemLogo">
                                        <img src={Logo} alt="Gambar Press" style={{margin: 'auto'}} />
                                    </div>
                                </Col>
                                <Col xs={24} sm={24} md={20} lg={20} xl={20}>
                                    <div className="itemLogo">
                                        <Typography.Title level={3}>Mahesa Sublim</Typography.Title>
                                    </div>
                                </Col>
                            </Row>
                            <br/>
                            <p>
                            {language === 'id'
                                ? 'Percayakan kebutuhan cetak kain Anda kepada kami, dan bersama-sama kita wujudkan ide-ide kreatif Anda menjadi kenyataan.'
                                : 'Trust your fabric printing needs to us, and together we will bring your creative ideas to life.'}
                            </p>
                        </div>
                    </Col>
                    <Col xs={24} sm={12} md={2} lg={2} xl={2}>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                    <div className="gridFooter">
                        <Typography.Title level={3}>{language === 'id' ? 'Navigasi' : 'Navigation'}</Typography.Title>
                            <Space direction='vertical'>
                                <Typography.Link strong href='/' >Beranda</Typography.Link>
                                <Typography.Link strong href='/tentang-kami' >Tentang Kami</Typography.Link>
                                <Typography.Link strong href='/jenis-kain' >Jenis Kain</Typography.Link>
                                <Typography.Link strong href='/layanan-kami' >Layanan Kami</Typography.Link>
                                <Typography.Link strong href='/artikel' >Artikel</Typography.Link>
                                <Typography.Link strong href='/kontak' >Kontak</Typography.Link>
                            </Space>
                        </div>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                        <div className="gridFooter">
                        <Typography.Title level={3}>{language === 'id' ? 'Hubungi Kami' : 'Contact Us'}</Typography.Title>
                        <Space direction='vertical'>
                            <h4><MailOutlined />  mahesasublim@gmail.com</h4>
                            <h4><PhoneOutlined /> +6287781819779</h4>
                            <h4><InstagramFilled /> mahesasublim</h4>
                        </Space>
                        </div>
                    </Col>
                </Row>
            </div>
        
            <div className="copyright">
                <h3>Copyright 2024. Mahesa Sublim</h3>
            </div>

        </Footer>
        </>
    )
}

